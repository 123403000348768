import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Box,
  Grid,
  TablePagination,
} from "@mui/material";
import apiAuth from "../../customs/axiosCustom";
import DropPDF from "../User/PDFDroper";
import { toast } from "react-toastify";
import { useAuth } from "../../customs/authService";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";

const ImportCsvComponent = () => {
  const { t } = useAuth();
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState(null);
  const [data, setData] = useState([]);
  const [importData, setImportData] = useState([]);
  const [filteredData, setFilteredData] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setSelectedFile(selectedFile);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      toast.warning("importIntern.selectFileToImport");
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      showLoading();
      const response = await apiAuth.post(`/Import/csv`, formData);
      if (response.status === 200) {
        setData(response.data?.validRecords);
        toast.success(t(`importIntern.uploadAndSendMailSuccess`))
        setSelectedFile(null);
      }
    } catch (error) {
      const { data } = error.response;
      setData(data?.invalidRecords || []);
      toast.error(t(`importIntern.${data?.message}`));
    } finally {
      setSelectedFile(null);
      hideLoading();
      fetchData();
    }
  };

  const fetchData = async () => {
    try {
      const response = await apiAuth.post("/Import/ShowImportHistory");
      setImportData(response.data);
    } catch (error) {
      toast.error(t(`Import.failedToLoad`));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedFile) {
      handleSubmit();
      fetchData();
    }
  }, [selectedFile]);

  const handleSearch = async (event) => {
    event.preventDefault();
    if (filteredData != null) {
      const searchResponse = await apiAuth.post(
        `/Import/ShowImportHistory?fileName=${filteredData}`
      );
      setImportData(searchResponse.data);
    } else {
      setImportData(importData);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function formatDOB(dateString) {
    if (dateString != null) {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1; // Tháng tính từ 0
      const year = date.getFullYear();
      return `${day.toString().length === 1 ? `0${day}` : day}/${
        month.toString().length === 1 ? `0${month}` : month
      }/${year}`;
    }
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Import Interns from CSV
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mb={2}>
            <DropPDF onDrop={handleDrop} />
            {selectedFile && (
              <Typography variant="h6" style={{ marginLeft: 8 }}>
                {selectedFile.name}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      {data.length > 0 && (
        <Box mt={2}>
          <TableContainer
            component={Paper}
            style={{ maxHeight: 400, overflow: "auto" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Roll Number</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>First Name</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Last Name</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Date of Birth
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Lab Name</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Error Message
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => {
                  const errorsMes = item?.errorMessage
                    ? item.errorMessage.split(".").filter(Boolean)
                    : []; // Tách chuỗi lỗi thành mảng và loại bỏ các phần tử trống

                  return (
                    <TableRow key={index}>
                      <TableCell>{item.rollNumber}</TableCell>
                      <TableCell>{item.firstName}</TableCell>
                      <TableCell>{item.lastName}</TableCell>
                      <TableCell>{formatDOB(item.dob)}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.labName}</TableCell>
                      <TableCell
                        style={{
                          color: errorsMes.length > 0 ? "red" : "green",
                          fontWeight: "bold",
                        }}
                      >
                        {item?.errorMessage ? (
                          <ul>
                            {errorsMes.map((error, i) => (
                              <li key={i}>
                                {t(`importIntern.${error.trim()}`)}
                              </li> // Hiển thị từng lỗi dưới dạng danh sách
                            ))}
                          </ul>
                        ) : (
                          "Succeed"
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <Box mt={2}>
        <Typography variant="h6">Import History</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <TableContainer
              component={Paper}
              style={{ maxHeight: 300, overflow: "auto" }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell>Import Date</TableCell>
                    <TableCell>Is Success</TableCell>
                    <TableCell>Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {importData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.fileName}</TableCell>
                        <TableCell>{formatDOB(item.importDate)}</TableCell>
                        <TableCell
                          style={{ color: item.success ? "green" : "red" }}
                        >
                          {t(`importIntern.status.${item.success}`)}
                        </TableCell>
                        <TableCell>
                          {item.errorMessage &&
                            t(`importIntern.${item.errorMessage}`)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={importData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
          <Grid item xs={12} md={3} mt={-2}>
            <form onSubmit={handleSearch}>
              <TextField
                id="fileName"
                label="File Name"
                value={filteredData}
                onChange={(e) => setFilteredData(e.target.value)}
                fullWidth
                variant="outlined"
                margin="normal"
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Search
              </Button>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ImportCsvComponent;
