import React, { useEffect, useState } from "react";
import { useAuth } from "../../customs/authService";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import apiAuth from "../../customs/axiosCustom";
import DropPDF from "../User/PDFDroper";
import { toast } from "react-toastify";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";

const ImportMentor = () => {
  const { t } = useAuth();
  const [csvFile, setCsvFile] = useState(null);
  const [errorsCSV, setErrorsCSV] = useState([]);
  const [errors, setErrors] = useState([]);
  const [mentorData, setMentorData] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
  });

  const handleDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setCsvFile(selectedFile);
  };

  const handleMentorDataChange = (event) => {
    const { name, value } = event.target;
    setMentorData({
      ...mentorData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (csvFile) {
      handleCsvSubmit();
    }
  }, [csvFile]);

  const handleCsvSubmit = async () => {
    const formData = new FormData();
    formData.append("file", csvFile);

    try {
      showLoading();
      const response = await apiAuth.post("/Import/importMentorCSV", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        toast.success(t(`importMentor.${response.data.message}`));
        setErrorsCSV([]);
      }
    } catch (error) {
      const { data } = error.response;
      setErrorsCSV(data?.errorMessages || []);
      toast.error(t(`importMentor.${data.message}`));
    } finally {
      hideLoading();
    }
  };

  const handleMentorSubmit = async (event) => {
    event.preventDefault();
    try {
      showLoading();
      const response = await apiAuth.post("/Import/ImportMentor", mentorData);

      if (response.status === 200) {
        toast.success(t(`importMentor.${response.data.message}`));
        setErrors([]);
        setMentorData({
          FirstName: "",
          LastName: "",
          Email: "",
        });
      }
    } catch (error) {
      const { data } = error.response;
      Object.keys(data).forEach((key) => {
        setErrors(data.message || []);
      });
      toast.error(t(`importMentor.rowsAreInvalid`));
    } finally {
      hideLoading();
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Import Mentor CSV
      </Typography>
      <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
        <Box mb={2}>
          <DropPDF onDrop={handleDrop} />
          {csvFile && <Typography variant="h6">{csvFile.name}</Typography>}
        </Box>
        {errorsCSV.length > 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Line Number
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Errors
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {errorsCSV.map((error, index) => {
                  const parts = error.split(":");
                  const lineNumber = parts[0];
                  const errorMessage = parts.slice(1).join(".").trim();

                  return (
                    <TableRow key={index}>
                      <TableCell
                        align="center"
                        style={{ fontSize: "18px", color: "red" }}
                      >
                        {lineNumber}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontSize: "18px", color: "red" }}
                      >
                        {t(`importMentor.${errorMessage}`)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>

      <Typography variant="h4" gutterBottom>
        Add Mentor
      </Typography>
      <Paper elevation={3} sx={{ p: 2 }}>
        <form onSubmit={handleMentorSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                name="FirstName"
                value={mentorData.FirstName}
                required
                onChange={handleMentorDataChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="LastName"
                value={mentorData.LastName}
                required
                onChange={handleMentorDataChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="Email"
                required
                value={mentorData.Email}
                onChange={handleMentorDataChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={2} mb={3}>
            <Button type="submit" variant="contained" color="primary">
              Add Mentor
            </Button>
          </Box>
        </form>
        {errors.length > 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Error
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {errors.map((error, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ fontSize: "18px", color: "red" }}>
                      {t(`importMentor.${error}`)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Container>
  );
};

export default ImportMentor;
